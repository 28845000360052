<template>
  <!-- 关键词 文章列表 -->
  <div class="container">
    <!-- 导航 -->
    <V-ArticleNav class="headClass" :navList="navList"></V-ArticleNav>
    <div class="gWbc title"> 
      发布纸质资料数字化加工
    </div>
    <div class="gWbc basicInfo">
      <div class="bi_top_btn">填写基本信息</div>
      <div class="typeIn" style="margin-top:30px">
        <div class="inLeft">
          <span>*</span>
          <span>任务名称：</span>
        </div>
        <div class="inRight">
          <el-input class="right_input" v-model="release.taskname" @input="textLength" @blur="textRepetition"
          placeholder="请输入30字内的任务名称"></el-input>
          <div class="rightTs">提示：最多输入30个字，且任务名称不能重复</div>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>任务简介：</span>
        </div>
        <div class="inRight">
          <el-input type="textarea" v-model="release.describe" resize="none" class="right_input" style="height:100px"
          placeholder="请输入任务简介"></el-input>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>加工类型：</span>
        </div>
        <div class="inRight">
          <div class="right_input right_input_radio">
            <el-radio v-model="release.type" label="会议">会议</el-radio>
            <el-radio v-model="release.type" label="档案">档案</el-radio>
          </div>
        </div>
      </div>
      <div class="typeIn" style="margin-top:30px">
        <div class="inLeft">
          <span>上传扫描件：</span>
        </div>
        <div class="inRight">
          <el-upload
            class="right_input right_input_btn"
            :action="fileUrl"
            :headers="{token}"
            :data="{userName:loginName,type:'1'}"
            :show-file-list="false"
            :on-error="updateError"
            :on-success="updateSuccess"
            :before-upload="beforeAvatarUpload">
            <el-button size="small" type="primary">
              {{release.zipPath?fileName:'请上传扫描件压缩包'}}
            </el-button>
          </el-upload>
          <div class="rightTs">
            提示：①扫描件需要压缩为zip格式上传； 
            <div>②压缩文件里面不能有文件夹</div>
          </div>
        </div>
      </div>


      <div class="typeIn" style="margin-top:30px">
        <div class="inLeft">
          <!-- <span>*</span> -->
          <span>压缩图片：</span>
        </div>
        <div class="inRight">
          <el-upload 
            class="upload-demo"
            multiple
            list-type="picture"
            name="file"
            ref="loadImgList"
            :auto-upload="true"
            :action="fileUrl1"
            :headers="{token}"
            :show-file-list="false"
            :before-upload="beforeImgListUpdate"
            :on-error="updateError"
            :on-success="ImgListUpdateSuccess"
            >
            <el-button size="small" type="primary">点击上传</el-button>
            <div v-show="ysImgList.length<=0" slot="tip" class="el-upload__tip">请上传后缀为jpg、jpeg、png、gif、bmp的图片文件,单个文件大小不能超过200MB，最多上传9个文件</div>
            <div class="imgListLoad" v-for="(item,i) of ysImgList" :key="i" @click.stop="">
              <span>{{item.name}}</span>
              <div class="imgListLoad_btn">
                <div @click.stop="tapLoadImg(item)">下载</div>
                <div @click.stop="tapDeleteImg(i)">删除</div>
              </div>
            </div>
          </el-upload>
        </div>
      </div>


      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>成果格式：</span>
        </div>
        <div class="inRight">
          <div class="right_input right_input_radio">
            <el-radio v-model="release.resultformat" disabled label="pdf">pdf</el-radio>
          </div>
        </div>
      </div>
    </div>
    <div class="gWbc basicInfo">
      <div class="bi_top_btn">设置加工工序</div>
      <div class="typeIn" style="margin-top:30px">
        <div class="inLeft">
          <span>*</span>
          <span>是否分页：</span>
        </div>
        <div class="inRight">
          <div class="right_input right_input_radio">
            <el-radio v-model="release.isPaging" label="1">是</el-radio>
            <el-radio v-model="release.isPaging" label="2">否</el-radio>
          </div>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>全文录入：</span>
        </div>
        <div class="inRight">
          <div class="right_input right_input_radio">
            <el-radio v-model="release.isFullTextEntry" label="1">是</el-radio>
            <el-radio v-model="release.isFullTextEntry" label="2">否</el-radio>
          </div>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>校对方式：</span>
        </div>
        <div class="inRight">
          <div class="right_input right_input_radio">
            <el-radio v-model="release.checkWay" disabled label="1">一录一校</el-radio>
          </div>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft">
          <span>数字化加工文件:</span>
        </div>
        <div class="inRight" style="width: 443px;">
          <div class="right_input right_input_checkbox">
            <el-checkbox-group v-model="checkList" @change="checkboxList">
              <el-checkbox label="文字录入-word">文字录入-word</el-checkbox>
              <el-checkbox label="表格-Excel">表格-Excel</el-checkbox>
              <el-checkbox label="图纸-CAD">图纸-CAD</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>自定义著录项：</span>
        </div>
        <div class="inRight" style="width：422px;">
          <div class="inputs" v-for="(item,i) of inputList" :key="i">
            <el-input class="right_input" v-model="inputList[i]" placeholder="请输入10字内的著录项"
             @input="(e)=>{inputListLength(e,i)}" @blur="inputAdd"></el-input>
            <div class="inputs_btn" v-if="(inputList.length-1)==i" @click="addInput(i,'+')">
              <i class="el-icon-plus"></i>
            </div>
            <div class="inputs_btn" v-if="(inputList.length-1)!=i" @click="addInput(i,'-')">
              <i class="el-icon-minus"></i>
            </div>
          </div>
          <div class="rightTs">点击“+”可以添加栏目</div>
        </div>
      </div>
    </div>
    <div class="gWbc basicInfo">
      <div class="bi_top_btn">设置加工质量</div>
      <div class="typeIn" style="margin-top:30px">
        <div class="inLeft">
          <span>*</span>
          <span>录入错误率：</span>
        </div>
        <div class="inRight">
          <div class="inputs errors">
            <span>录入错误率小于</span>
            <el-input class="right_input error_input" v-model="release.errorRate"
            @input="errorRateInput"></el-input>
            <span>%</span>
          </div>
          <div class="rightTs">
            提示：①录入错误率=校对统计 的错别字数/录入的总字数
            <div>②录入错误率的值为0~100区间内的整数</div>
          </div>
        </div>
      </div>
    </div>
    <div class="gWbc basicInfo">
      <div class="bi_top_btn">设置商务要求</div>
      <div class="typeIn" style="margin-top:30px">
        <div class="inLeft">
          <span>*</span>
          <span>加工工期：</span>
        </div>
        <div class="inRight" style="width:420px;">
          <div class="inputs">
            <el-input class="right_input" @input="(e)=>{inputNum(e,'totaldays')}"
             v-model="release.totaldays"
             style="width:200px" placeholder="请输入天数(正整数)"></el-input>
            <span style="margin-left:10px">天</span>
          </div>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>加工者信用要求：</span>
        </div>
        <div class="inRight" style="width: 467px;text-align:left">
          <el-select v-model="release.userCreditLevel" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>负责人酬劳：</span>
        </div>
        <div class="inRight">
          <div class="inputs">
            <el-input class="right_input" @input="(e)=>{inputNum(e,'fzr_totalmoney')}"
             style="width:200px" 
            placeholder="请输入知识币数(正整数)" v-model="release.fzr_totalmoney"></el-input>
            <span style="margin-left:10px">知识币</span>
          </div>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>任务总酬劳：</span>
        </div>
        <div class="inRight">
          <div class="inputs">
            <el-input class="right_input" @input="(e)=>{inputNum(e,'totalmoney')}" style="width:200px"
             placeholder="请输入知识币数(正整数)" v-model="release.totalmoney"></el-input>
            <span style="margin-left:10px">知识币</span>
          </div>
        </div>
      </div>
      <div class="typeIn in_btn">
        <div class="inLeft_btn" @click="tapCancel">取消</div>
        <div class="inRight_bnt" @click="tapRelease">发布</div>
      </div>
    </div>
  </div>
</template> 

<script>
import axios from 'axios'
import ArticleNav from "@/components/article-nav/index.vue";
import { QUERYED,UPDATES,INSERT,DELETE } from "@/services/dao.js"; 
import { Loading } from 'element-ui';
import api from "@/dev/api.ts";
import {Bringbill} from "@/js/common";
export default {
  components: {
    "V-ArticleNav": ArticleNav,
  }, 
  data() {
    return {
      navList:[{word:'发布'}],  //导航
      release:{ //发布
        taskname:'',  //任务名称
        userid:'',  //发布者id
        username:'',  //发布者姓名
        describe:'',  //任务简介
        type:'',  //任务类型  值为：会议、档案
        zipPath:'',  //上传的压缩包路径
        imgPath:''  ,  //解压压缩包里面的图片路径  逗号分开
        resultformat:'pdf',   // 成果格式 值为：xml、sqlite
        isPaging:'',  //是否分页  1为是  2为否
        isFullTextEntry:'',  //是否全文录入  1为是  2为否
        checkWay:'1',  //校对方式  1为一录一校
        processingFiles:'', //数字加工文件   值为：文字录入-word，表格-Excel,图纸-CAD。多选使用逗号隔开
        customEntry:'',  //自定义著录入   逗号隔开
        errorRate:'',  //错误率
        totaldays:'',  //任务工期
        userCreditLevel:'',  //用户信用等级
        totalmoney:'',  //总酬劳
        fzr_totalmoney:'',  //负责人酬劳
        jgFzrAndTaskId:null,  //发布者已确认的负责人  发布时默认空
        status:0 //任务状态  默认0
      },
      checkList:[],//数字加工文件   
      inputList:[''],  //自定义著录入
      options: [  //用户信用等级选项
        {value: '0',label: '无要求' }, 
        {value: '1',label: '0-10级'}, 
        {value: '2',label: '11-20级'}, 
        {value: '3',label: '21级以上'} 
      ],   
      fileUrl:api.LoginURL.concat('/common/unzipFile'),  //上传文件路径
      // fileUrl1:'http://127.0.0.1:8089/creatThumbnail',  //上传文件路径
      fileUrl1:api.LoginURL.concat('/common/thumbnail'),  //上传文件路径
      fileName:'',  //上传文件名

      residueMoney:0,  //当前余额 
      zsjg_prepay:0,  //用户被冻结余额
      loginName:'',  //用户登录名
      LoadingIcon:null,  //加载
      token:'',  
      isExist:false,   //任务名称是否重复

      ysImgList:[],

    };
  },
  created(){
    let userid = this.$store.state.userinfo.id;   
    if(!userid){
      this.$alert('请登录后再发布!', '提示', {
        confirmButtonText: '确定',
        callback: () => {
          this.$router.push({path:'/login'})
        }
      });
      return
    }  
    this.token = localStorage.getItem('Authorization')
    this.release.userid = userid
    this.getUserInfo(userid)
  },
  methods: {
    //多图上传前获取图片
    beforeImgListUpdate(file){
      let {name,size} = file
      let typeList = ['jpg','jpeg','png','gif','bmp']
      let list = name?name.split('.'):[]
      let type = list[list.length-1]
      let maxSize = 209715200
      if(size>maxSize){
        this.msgWarn('上传文件最大为200MB')
        return false
      }
      if(typeList.indexOf(type.toLowerCase())<0){
        this.msgWarn('请上传后缀为jpg、jpeg、png、gif、bmp的图片文件')
        return false
      }
      this.LoadingIcon = Loading.service()
      return true
    },

    prpgressImg(e,file,filelist){
      console.log(e,file,filelist);
    },

    //多图上传成功
    ImgListUpdateSuccess(response, file){
      this.LoadingIcon.close()
      if(!response.success){
        let text = response.message||response.respMsg
        if(text.indexOf(':')>-1){
          text = text.split(':')[1]
        }
        this.msgWarn(text)
      }else{
        console.log(response.data[0],456);
        let imgUrl = response.data[0]
        let name = file.name
        // imgUrl = 'http://www.izhihui.net/bak/resources/trading/upload'+imgUrl.split('uploadFile')[1]
        // imgUrl = imgUrl.split('uploadFile')[1]
        // api.LoginURL+'/resources/trading/upload'+
        
        // imgUrl = 'http://www.izhihui.net/bak/resources/trading/upload/thumbnail/土拍.jpg'
        this.ysImgList.push({name,imgUrl})
      }
      this.$$refs.loadImgList.clearFiles();
    },


    tapImg(file){
      // console.log(file.response.data[0],456);
      let imgUrl = file.response.data[0]
      imgUrl = api.LoginURL+'/resources/trading/upload'+imgUrl.split('uploadFile')[1]
      console.log(imgUrl,123);
    },

    //文件超数量提示
    // exceedFile(){
    //   this.msgWarn('最多上传9个文件')
    // },

    //删除图片
    tapDeleteImg(i){
      this.ysImgList.splice(i,1)
    },

    //下载图片
    tapLoadImg(item){ 
      console.log(item);
      let formData = new FormData()
      formData.append('path',item.imgUrl)
      axios
      .post(
        this.api.LoginURL.concat("/common/zsjgFileDown"),
        formData,
        {
          responseType: "blob" //服务器返回的数据类型
        }
      )
      .then(res => {
        console.log(res,123);
        let read = new FileReader();
        read.readAsDataURL(res.data);
        read.onload = a => {
          let fileName = item.name;
          let link = document.createElement("a"); //创建a标签
          link.download = fileName; //a标签添加属性
          link.href = a.target.result;
          link.click(); //执行下载
        };               
      });
      // let image = new Image();
      // // 解决跨域 Canvas 污染问题
      // image.setAttribute("crossOrigin", "anonymous");
      // image.onload = function() {
      //   // let canvas = document.createElement("canvas");
      //   // canvas.width = image.width;
      //   // canvas.height = image.height;
      //   // let context = canvas.getContext("2d");
      //   // context.drawImage(image, 0, 0, image.width, image.height);
      //   // let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      //   let a = document.createElement("a"); // 生成一个a元素
      //   let event = new MouseEvent("click"); // 创建一个单击事件
      //   a.download = item.name; // 设置图片名称
      //   a.href = item.imgUrl; // 将生成的URL设置为a.href属性
      //   a.dispatchEvent(event); // 触发a的单击事件
      // };
      // image.src = item.imgUrl;
    },

    //获取去用户信息
    async getUserInfo(id){
      let res = await QUERYED(
        "post","",
        "User(where:{id: {_eq:"+ id +"}}) { id name Money zsjg_prepay loginName }"
      );
      if(res.success){
        this.release.username = res.data.User[0].name
        this.residueMoney = res.data.User[0].Money
        this.loginName  = res.data.User[0].loginName
        this.zsjg_prepay  = res.data.User[0].zsjg_prepay
      }
    },

    //限制字数
    textLength(e){
      if(e.length>30){
        this.msgWarn('最大长度为30个字')
        this.release.taskname = e.slice(0,30)
      }
    },

    //查看任务名称是否重复
    async textRepetition(e){
      let value = this.pdIsEmpty(e.target.value)
      if(value){
        let res = await QUERYED(
          "post","",
          "jgPeper_aggregate(where:{taskname: {_eq:\""+ value +"\"}}) { aggregate {count}}"
        );
        if(res.success){
          let isExist = res.data.jgPeper_aggregate.aggregate.count>0
          this.isExist = isExist
          if(isExist){
            this.msgWarn('当前任务名称已存在')
          }
        }
      }
    },

    //数字加工文件选择
    checkboxList(e){
      this.release.processingFiles = e + ''
      console.log(123);
    },

    //添加自定义著录入
    addInput(i,type){
      if(type=='+')this.$set(this.inputList,i*1+1,'')
      else this.inputList.splice(i,1)
      this.inputAdd()
    },

    //自定义著录入项光标离开时
    inputAdd(){
     this.release.customEntry = this.inputList+''
    },

    //自定义著录入项输入时长度判断
    inputListLength(e,i){
      if(e.length>10){
        this.msgWarn('最大长度为10个字')
        this.$set(this.inputList,i,e.slice(0,10))
      }
    },

    //录入错误率判断
    errorRateInput(e){  
      let val = e.replace(/[^\d]/g,'')
      if(val<0){
        val = 0
        this.msgWarn('输入最小值为0');
      }
      if(val>100){
        val = 100
        this.msgWarn('输入最大值为100');
      }
     this.release.errorRate= val
    },

    //判断输入的是否为数字
    inputNum(e,name){
      this.release[name] = e.replace(/[^\d]/g,'')*1
    },

    //判断值是否为空
    pdIsEmpty(e){
      return e.replace(/ /g,'')
    },

    //警告弹窗
    msgWarn(message,type){
      this.$message({
        message,type: type||'warning'
      });
    },

    //点击取消时
    tapCancel(){
      this.$router.go(-1)
    },

    //文件上传失败
    updateError(err){
      console.log(err);
      this.msgWarn('文件上传失败,请重试')
      this.LoadingIcon.close()
    },

    //上传前文件判断
    beforeAvatarUpload(file){
      let {name,size} = file
      let list = name?name.split('.'):[]
      let type = list[list.length-1].toLowerCase()
      let maxSize = 209715200
      if(size>maxSize){
        this.msgWarn('上传文件最大为200MB')
        return false
      }
      if(type!='zip'){
        this.msgWarn('请上传后缀为zip的压缩文件')
        return false
      }
      this.LoadingIcon = Loading.service()
      return true
    },

    //文件上传成功
    updateSuccess(response, file){
      this.LoadingIcon.close()
      if(!response.success){
        let text = response.message||response.respMsg
        if(text.indexOf(':')>-1){
          text = text.split(':')[1]
        }
        this.msgWarn(text)
      }else{
        let {imgs,zipUrl} = response.data
        this.release.zipPath = zipUrl
        this.release.imgPath = imgs+''
        this.fileName = file.name
      }
    },

    //点击发布时
    tapRelease(){
      let {release,pdIsEmpty,msgWarn,residueMoney,isExist,inputList} = this
      let {taskname,describe,type,zipPath,resultformat,isPaging,isFullTextEntry,
      customEntry,errorRate,totaldays,userCreditLevel,totalmoney,fzr_totalmoney} = release
      if(!pdIsEmpty(taskname)){
        msgWarn('请输入任务名称')
        return
      }
      if(isExist){
        msgWarn('当前任务名称已存在')
        return
      }
      if(!pdIsEmpty(describe)){
        msgWarn('请输入任务简介')
        return
      }
      if(!type){
        msgWarn('请选择任务类型')
        return
      }
      // if(!zipPath){
      //   msgWarn('请上传扫描件压缩包')
      //   return
      // }
      if(!resultformat){
        msgWarn('请选择成果格式')
        return
      }
      if(!isPaging){
        msgWarn('请选择是否分页')
        return
      }
      if(!isFullTextEntry){
        msgWarn('请选择是否全文录入')
        return
      }
      for(let item of inputList){
        if(!pdIsEmpty(item)){
          msgWarn('请填写著录项')
           return
        }
      }
      // if(!customEntry){
      //   msgWarn('请填写著录项')
      //   return
      // }
      if(!errorRate){
        msgWarn('请输入错误率')
        return
      }
      if(!totaldays){
        msgWarn('请输入任务工期')
        return
      }
      if(!userCreditLevel){
        msgWarn('请选择加工者信用等级')
        return
      }
      if(!fzr_totalmoney){
        msgWarn('请输入负责人酬劳')
        return
      }
      if(!totalmoney){
        msgWarn('请输入总酬劳')
        return
      }
      if(fzr_totalmoney>=totalmoney){
        msgWarn('负责人酬劳必须小于总酬劳')
        return
      }
       if(residueMoney<totalmoney){
        this.$alert(`您的余额不足，当前账户余额为${residueMoney}知识币`, '', {
          confirmButtonText: '确定',
          callback: () => {}
        });
        return
      }
      this.$confirm(`你的账户知识币余额为${residueMoney.toFixed(2)}知识币，您确定要支付${totalmoney.toFixed(2)}知识币发布任务？`, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.LoadingIcon = Loading.service()
        this.tapPay(release)
      }).catch(() => {
        msgWarn('用户取消支付')
      });
    },

    //用户点击支付
    async tapPay(release){
      let {residueMoney,zsjg_prepay,msgWarn} = this
      let {userid,totalmoney} = release
      let strRelease = ''
      let date = new Date()*1+''
      release.id = date.slice(date.length-9+(userid+'').length,date.length) + userid
      release.issueTime = date*1
      for(let name in release){
        strRelease += name
        if(typeof release[name] == 'string') strRelease = strRelease+':"'+release[name]+'",'    
        else strRelease = strRelease+':'+release[name]+','
      }

      //插入发布数据
      strRelease = strRelease.slice(0,strRelease.length-1)
      let installZSJG = await INSERT(
        "POST","",
        'insert_jgPeper(objects:{'+ strRelease +'}) {affected_rows returning {id}}'
      )
      if(!installZSJG.success){
        this.LoadingIcon.close()
        msgWarn('发布失败，请重试')
        return
      }

      //扣除知识币
      let pay = await UPDATES(
        "post","",
        "update_User(_set: {Money:"+(residueMoney-totalmoney)+", zsjg_prepay:"+(zsjg_prepay*1+totalmoney*1)+"}, where: {id: {_eq: "+userid+"}}) {  affected_rows }"
      )
      let tsakid = installZSJG.data.insert_jgPeper.returning[0].id
      if(!pay.success){
        //支付失败时撤销发布数据
        await DELETE(
          "post","",
          'delete_jgPeper(where: {id:{_eq:'+tsakid+'}}) {affected_rows}'
        )
        this.LoadingIcon.close() 
        this.msgWarn('支付失败，请重试')
        return  
      }
      this.LoadingIcon.close()
      this.msgWarn('发布成功','success')
      this.setData(tsakid)
      Bringbill.call(this).then(orderNum=>{
        this.installOrder(orderNum,totalmoney,userid,tsakid)
      })
    },

    //新增订单号
    async installOrder(orderNum,totalmoney,userid,tsakid){
      await INSERT(
        "POST","",
        "insert_bill(objects:{orderid:"+tsakid+",time:\""+new Date()+"\",type:4,state:1,userid:"+userid
        +",orderNum:\""+orderNum+"\",Price:\""+totalmoney+"\",classification:1}) {affected_rows}"
        // 'insert_bill(objects:{time:"'+
        //   new Date()
        // +'",type:4,orderNum:"'+
        //   orderNum
        // +'",Price:"'+
        //   totalmoney+
        // '",classification:1,userid:'+
        //   userid
        // +'orderid:'+
        //   tsakid
        // +',state:0}) {affected_rows}'
      )
    },

    //重新设置数据
    setData(tsakid){
      let id = this.release.userid
      this.release={ 
        taskname:'',userid:'',username:'',describe:'',type:'',zipPath:'',imgPath:'',resultformat:'',
        isPaging:'',isFullTextEntry:'',checkWay:'1',processingFiles:'',customEntry:'',totaldays:'',
        userCreditLevel:'',totalmoney:'', fzr_totalmoney:'',jgFzrAndTaskId:null,status:0 
      }
      this.checkList = []
      this.inputList = ['']
      this.fileUrl=api.LoginURL.concat('/common/unzipFile'),  
      this.fileName=''
      // this.getUserInfo(id)
      this.$router.push({path:'/taskDetail',query:{id:tsakid,type:0,isNotBack:1}})
    }
  }
};
</script>

<style scoped>
.container {
  text-align: center;
  background-color: #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headClass {
  width: 1090px;
  height: 66px;
  line-height: 66px;
  text-align: left;
}
.gWbc{
  width: 1090px;
  background-color: #fff;
}
.title{
  height: 72px;
  line-height: 72px;
  text-align: center;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 3px solid #eee;
}
.basicInfo{
  /* margin-bottom: 30px; */
  padding-top: 18px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 3px solid #eee;
}
.bi_top_btn{
  width: 380px;
  height: 36px;
  background: #EEEEEE;
  border: 1px solid #D2D2D2;
  text-align: center;
  line-height: 36px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.typeIn{
  margin-top: 17px;
  display: flex;
}
.inLeft{
  padding-top: 8px;
  text-align: right;
  font-family: Microsoft YaHei;
  font-weight: 400;
  display: flex;
  align-items:flex-start;
}
.inLeft>span:first-child{
  color: #D50005;
  font-size: 20px;
}
.inLeft>span:last-child{
  margin-left: 10px;
  font-size: 14px;
  color: #000000;
}
.inRight{
  width: 390px;
  margin-left: 10px;
}
.right_input{
  width: 100%;
  height: 36px;
}
.right_input_radio{
  line-height: 36px;
  text-align: left;
}
.right_input_checkbox{
  width: 590px;
  line-height: 36px;
  text-align: left;
}
.right_input_btn>>>.el-upload{
  width: 100%;
  height: 100%;
}
.right_input_btn>>>.el-button{
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 36px;
  padding:0;
  background-color: #0096FF;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FEFFFE;
  cursor: pointer;
}
/* .right_input_btn:hover{
  opacity: 0.9;
} */
.rightTs{
  margin-top: 10px;
  font-size: 14px;
  text-align: left;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #676767;
}
.rightTs>div{
  margin-left: 42px;
}
.right_input>>>.el-textarea__inner{
  height: 100px;
}
.inputs{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.inputs_btn{
  width: 25px;
  height: 25px;
  margin-left: 20px;
  border:1px solid #999;
  border-radius: 3px;
  text-align: center;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.inputs_btn>i{
  font-size: 16px;
  color: #000000;
}
.errors{
  align-items: flex-end;
}
.error_input{
  width: 60px;
  height: 27px;
  margin:0 10px;
}
.error_input>>>.el-input__inner{
  height: 27px;
  padding:0 10px;
  border-width: 0;
  border-bottom-width:1px;
  border-radius: 0;
}
.in_btn{
  width: 700px;
  margin-top: 70px;
  justify-content: space-between;
}
.in_btn>div{
  width: 130px;
  height: 36px;
  background-color: #EEEEEE;
  border: 1px solid #D2D2D2;
  border-radius: 5px;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #011603;
  cursor: pointer;
}
.in_btn>div:hover{
  opacity: 0.8;
}
div.inRight_bnt{
  background-color: #0084FF;
  border: 1px solid #0084FF; 
  color:#fff
}
.imgListLoad{
  width: 400px;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.imgListLoad>span{
  width: 251px;
  font-size: 14px;
  text-align: left;
  color: #999;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.imgListLoad_btn{
  display: flex;
}
.imgListLoad_btn>div{
  width: 50px;
  height: 25px;
  border-radius: 5px;
  background-color: #409EFF;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
}
.imgListLoad_btn>div+div{
  margin-left: 20px;
}
</style>
